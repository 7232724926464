import { isInViewport, htmlToNode } from '../utilities.js';

// ### Par défaut
// {% if recaptcha_site_key() %}
//   <input type="hidden" name="g-recaptcha-response" data-recaptcha-site-key="{{ recaptcha_site_key() }}" data-recaptcha-action="recaptcha_{{ form.id }}">
// {% endif %}
// <button type="submit" class="c-button c-form__button g-recaptcha">{{ submit_text }}</button>

// ### Avec bandeau RGPD tarteaucitron
// {% if recaptcha_site_key() %}
//   <input type="hidden" name="g-recaptcha-response" data-recaptcha-site-key="{{ recaptcha_site_key() }}" data-recaptcha-action="recaptcha_{{ form.id }}">
// {% endif %}
// {% set submit %}
//   <button type="submit" class="c-button c-form__button g-recaptcha">{{ submit_text }}</button>
// {% endset %}
// <div class="g-recaptcha" data-button="{{ submit|trim|e }}"></div>

const grecaptcha_hidden_fields = [];

let grecaptcha_global_loaded = false;
window.grecaptcha_global_callback = () => {
  grecaptcha_global_loaded = true;

  grecaptcha_hidden_fields.forEach((hiddenElement) => {
    const action = hiddenElement.dataset.recaptchaAction;
    const site_key = hiddenElement.dataset.recaptchaSiteKey;

    grecaptcha.ready(() => {
      grecaptcha.execute(site_key, {action: action}).then((token) => {
        hiddenElement.setAttribute('value', token);
        if (hiddenElement.dataset.submitMe) {
          hiddenElement.closest('form').submit();
        }
      });
    });
  });
};

document.addEventListener('DOMContentLoaded', () => {
  const windowElement   = window,
    bodyElement         = document.querySelector('body');

  let grecaptcha_global_load_inited = false;
  const autoload_recaptcha_forms = [];

  const init_recaptcha = (formElement) => {
    const hiddenElement = formElement.querySelector('input[name="g-recaptcha-response"]');

    if (!grecaptcha_hidden_fields.includes(hiddenElement)) {
      grecaptcha_hidden_fields.push(hiddenElement);
    }

    if (grecaptcha_global_loaded) {
      grecaptcha_global_callback();
      return;
    }
    
    if (grecaptcha_global_load_inited) return;
    grecaptcha_global_load_inited = true;

    const site_key = hiddenElement.dataset.recaptchaSiteKey;
    const scriptElement = document.createElement('script');
    scriptElement.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=' + site_key + '&onload=grecaptcha_global_callback');
    bodyElement.append(scriptElement);
  };

  const scroll_listener = () => {
    autoload_recaptcha_forms.forEach((formElement) => {
      if (isInViewport(formElement)){
        const index = autoload_recaptcha_forms.indexOf(formElement);
        autoload_recaptcha_forms.splice(index, 1);
        if (autoload_recaptcha_forms.length == 0) {
          windowElement.removeEventListener('scroll', scroll_listener);
        }

        init_recaptcha(formElement);
      }
    });
  };
  windowElement.addEventListener('scroll', scroll_listener);

  const submit_form = (event) => {
    const formElement = event.target;

    const submitElement = formElement.querySelector('button[type=submit]');
    if (!submitElement) {
      event.preventDefault();
      return;
    }
    
    submitElement.disabled = true;

    const hiddenElement = formElement.querySelector('input[name="g-recaptcha-response"]');
    if (hiddenElement.getAttribute('value')) {
      return;
    }

    hiddenElement.dataset.submitMe = true;
    init_recaptcha(formElement);
    event.preventDefault();
  };

  document.querySelectorAll('.g-recaptcha').forEach((submitElement) => {
    const formElement = submitElement.closest('form');
    const hiddenElement = formElement.querySelector('input[name="g-recaptcha-response"]');

    if (!hiddenElement) {
      return;
    }

    formElement.addEventListener('submit', submit_form);

    if (submitElement.matches('button[type=submit]')) {
      autoload_recaptcha_forms.push(formElement);
    }
    else if (typeof(tarteaucitron) != 'undefined') {
      (tarteaucitron.job = tarteaucitron.job || []).push('recaptcha');
      tarteaucitron.user.recaptchaapi = hiddenElement.dataset.recaptchaSiteKey;

      tarteaucitron.user.recaptchaOnLoad = () => {
        submitElement.replaceWith(htmlToNode(submitElement.dataset.button));

        grecaptcha_global_loaded = true;              // exécution auto de grecaptcha_global_callback
        grecaptcha_global_load_inited = true;         // ne pas charger recaptcha/api.js deux fois

        // init_recaptcha(formElement);               // génération du token tout de suite : non
        autoload_recaptcha_forms.push(formElement);   // reprise de la détection de scroll
      };
    }
  });
});
