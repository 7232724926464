import Typed from 'typed.js';

const typedElement = document.querySelector('#js-typed');
if (typedElement) {
  const typed = new Typed(typedElement, {
    stringsElement: '#js-typed-strings',
    typeSpeed: 60,
    backSpeed: 30,
    backDelay: 1000,
    loop: true
  });
}