import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';

document.addEventListener('DOMContentLoaded', () => {

  const lightbox = new PhotoSwipeLightbox({
    gallery: '.js-gallery',
    children: 'a',
    showHideAnimationType: 'fade',
    pswpModule: PhotoSwipe,
  });

  lightbox.on('uiRegister', () => {
    lightbox.pswp.ui.registerElement({
      name: 'custom-caption',
      order: 9,
      isButton: false,
      appendTo: 'root',
      html: '',
      onInit: (el, pswp) => {
        lightbox.pswp.on('change', () => {
          const currSlideElement = lightbox.pswp.currSlide.data.element;
          let captionHTML = '';
          if (currSlideElement) {
            const hiddenCaption = currSlideElement.querySelector('.hidden-caption-content');
            if (hiddenCaption) {
              // get caption from element with class hidden-caption-content
              captionHTML = hiddenCaption.innerHTML;
            } else {
              // get caption from alt attribute
              captionHTML = currSlideElement.querySelector('img').getAttribute('alt');
            }
          }
          el.innerHTML = '<div>' + (captionHTML || '') + '</div>';
        });
      }
    });
  });

  lightbox.init();

  document.querySelectorAll('.js-gallery-launcher').forEach((launcher) => {
    launcher.addEventListener('click', (event) => {
      event.preventDefault();
      document.querySelector('#' + launcher.dataset.target + ' a').click();
    });
  });

});
