import './front/aos.js';              // 14 ko
import './front/browserizr.js';       // 5 ko
import './front/external-links.js';
import './front/menu-offcanvas.js';
import './front/menu-sticky.js';
import './front/outdated-browser.js';
import './front/photoswipe.js';       // 76 ko
import './front/recaptcha.js';
import './front/scroll-to.js';
import './front/typed.js';            // 10 ko
import './front/vhcheck.js';          // 3 ko

const htmlElement = document.querySelector('html');
htmlElement.classList.remove('no-js');
htmlElement.classList.add('js');
