document.addEventListener('DOMContentLoaded', () => {

  const headerElement   = document.querySelector('#header'),
    bodyElement         = document.querySelector('body'),
    windowElement       = window,
    documentElement     = document,
    class_hidden      = 'l-header--hidden',
    class_fixed       = 'l-header--fixed',
    sticky_offset     = 20, // sticky height 102
    header_height     = 60, // header height 138
    throttle_timeout  = 500;

  if (!headerElement) return true;

  let w_height        = 0,
    w_scroll_current  = 0,
    w_scroll_before   = 0,
    w_scroll_diff     = 0,
    d_height          = 0,
    detection_margin  = null;

  // const throttle = (delay, fn) => {
  //   let last, defer_timer;
  //   return () => {
  //     const context = this, args = arguments, now = +new Date;
  //     if (last && now < last + delay) {
  //       clearTimeout(defer_timer);
  //       defer_timer = setTimeout(() => {
  //         last = now;
  //         fn.apply(context, args);
  //       }, delay);
  //     }
  //     else {
  //       last = now;
  //       fn.apply(context, args);
  //     }
  //   };
  // };

  const windowScroll = () => {
    if (bodyElement.classList.contains('no-scroll')) return;

    d_height          = document.body.scrollHeight;
    w_height          = windowElement.screen.height;
    w_scroll_current  = windowElement.scrollY;
    w_scroll_diff     = w_scroll_before - w_scroll_current;
    detection_margin  = headerElement.offsetHeight / 2;

    // class_fixed
    if (w_scroll_current < sticky_offset) {
      headerElement.classList.remove(class_fixed);
    }
    else if (w_scroll_current > header_height) {
      headerElement.classList.add(class_fixed);
    }

    // class_hidden
    // throttle(throttle_timeout, () => {
    //   if (w_scroll_current <= 0) { // scrolled to the very top; element sticks to the top
    //     headerElement.classList.remove(class_hidden);
    //   }
    //   else if (w_scroll_diff > 0 && headerElement.classList.contains(class_hidden)) { // scrolled up; element slides in
    //     headerElement.classList.remove(class_hidden);
    //   }
    //   else if (w_scroll_diff < 0) { // scrolled down
    //     if (w_scroll_current +  w_height >= d_height && headerElement.classList.contains(class_hidden)) { // scrolled to the very bottom; element slides in
    //       headerElement.classList.remove(class_hidden);
    //     }
    //     else if (w_scroll_current > detection_margin) { // scrolled down; element slides out
    //       headerElement.classList.add(class_hidden);
    //     }
    //   }
    // })();

    w_scroll_before = w_scroll_current;
  };

  windowElement.addEventListener('scroll', windowScroll);
  windowScroll();

});
