import { offset } from '../utilities.js';

document.addEventListener('DOMContentLoaded', () => {
  const click = (event) => {
    event.preventDefault();

    const target = event.target.dataset.target,
        windowElement = window;

    let menuHeight,
        windowWidth;

    const getWindowWith = () => {
      windowWidth = windowElement.screen.width;
    }

    getWindowWith();

    window.addEventListener('resize', () => {
      getWindowWith();
    });

    const targetElement = document.querySelector(target);

    // menuHeight = parseInt(targetElement.style.scrollMarginTop);
    if (windowWidth >= 750) {
      menuHeight = 102;

      if (!document.querySelector('#header').classList.contains('l-header--fixed')) {
        menuHeight += 36;
      }
    }
    else {
      menuHeight = 58;
    }

    menuHeight += 20;

    if (document.querySelector('#wpadminbar')) {
      menuHeight += 50;
    }

    let scrollTop = offset(targetElement).top;
    scrollTop -= menuHeight;

    window.scrollTo({top: scrollTop, behavior: 'smooth'});
  };

  document.querySelectorAll('.js-scroll-to').forEach((element) => {
    element.addEventListener('click', click);
  });
});
